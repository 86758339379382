import { create } from 'apisauce';

const apiUrl = {
  hml: 'http://localhost:4011',
  // eslint-disable-next-line no-undef
  prod: process.env.REACT_APP_API_URL,
};

const api = create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.NODE_ENV === 'production' ? apiUrl.prod : apiUrl.hml,
  headers: {
    Authorization: '',
  },
});

export default api;
